import { inDegrees } from './math.js'

const isValidValue = (val) => {
    return val !== null && val !== undefined
}

export const clampedHeightAtPoint = async ({ cesiumLib, cesiumViewer, lat, lng }) => {
  let height = 0
  if (cesiumLib && cesiumViewer) {
    try {
      const position = cesiumLib.Cartesian3.fromDegrees(lng, lat, 0)
      const scene = cesiumViewer.scene
      const clampedPosition = await scene.clampToHeightMostDetailed(
        [position]
      )
      const cartoPoint = cesiumLib.Cartographic.fromCartesian(clampedPosition?.[0])
      height = cartoPoint?.height ?? 0
      // console.log(`Cockpit camera height for (lat: ${lat}, lng: ${lng}) determined to be: ${height}`)
    } catch (error) {
      // console.log(`Error clamping cockpit cam height: ${error}`)
    }
  }
  return height
}

export const moveCesiumCamera = ({
  cesiumLib,
  cesiumViewer,
  location: { lat, lng },
  height,
  pitch,
  roll,
  heading,
}) => {
  if (cesiumViewer && cesiumLib) {
    try {
      // Preserve camera orientation across the transition (unless otherwise specified)
      const curCameraHeight = cesiumViewer.camera.positionCartographic.height
      const curCameraPitch = cesiumViewer.camera.pitch
      const curCameraRoll = cesiumViewer.camera.roll
      const curCameraHeading = cesiumViewer.camera.heading
      cesiumViewer.camera.setView({
        destination: cesiumLib.Cartesian3.fromDegrees(
          lng,
          lat,
          isValidValue(height) ? height : curCameraHeight
        ),
        orientation: {
          heading: isValidValue(heading)
            ? cesiumLib.Math.toRadians(heading)
            : curCameraHeading,
          pitch: isValidValue(pitch)
            ? cesiumLib.Math.toRadians(pitch)
            : curCameraPitch,
          roll: isValidValue(roll)
            ? cesiumLib.Math.toRadians(roll)
            : curCameraRoll,
        },
      });
    } catch (error) {
      // console.log(`Error updating cockpit cam orientation/location: ${error}`)
    }
  }
}

export const updateCesiumCameraOrientation = ({
  cesiumLib,
  cesiumViewer,
  heading,
  height,
  pitch,
  roll,
}) => {
  if (cesiumLib && cesiumViewer) {
    try {
      // Preserve camera location when setting height
      const curCameraLat = inDegrees(cesiumViewer.camera.positionCartographic.latitude)
      const curCameraLng = inDegrees(cesiumViewer.camera.positionCartographic.longitude)
      const newHeight = isValidValue(height) ? height : cesiumViewer.camera.height
      const destination = cesiumLib.Cartesian3.fromDegrees(
        curCameraLng,
        curCameraLat,
        newHeight
      )
      const curCameraPitch = cesiumViewer.camera.pitch
      const curCameraRoll = cesiumViewer.camera.roll
      const curCameraHeading = cesiumViewer.camera.heading
      cesiumViewer.camera.setView({
        destination,
        orientation: {
          heading: isValidValue(heading)
            ? cesiumLib.Math.toRadians(heading)
            : curCameraHeading,
          pitch: isValidValue(pitch)
            ? cesiumLib.Math.toRadians(pitch)
            : curCameraPitch,
          roll: isValidValue(roll)
            ? cesiumLib.Math.toRadians(roll)
            : curCameraRoll,
        },
      })
    } catch (error) {
      // console.log(`Error updating cockpit cam orientation: ${error}`)
    }
  }
}

export const setCesiumViewerProps = ({
  cesiumViewer,
  cesiumLib,
  resolutionScale
}) => {
  if (cesiumViewer && cesiumLib) {
    try {
      cesiumViewer.resolutionScale = resolutionScale
    } catch (error) {
      // console.log(`Error updating cockpit cam orientation/location: ${error}`)
    }
  }
}

export const getCesiumViewerProps = ({
  cesiumViewer,
  cesiumLib
}) => {
  let vProps = null
  if (cesiumViewer && cesiumLib) {
    vProps = {}
    try {
      vProps.resolutionScale = cesiumViewer.resolutionScale
      vProps.height = cesiumViewer.camera.positionCartographic.height
      vProps.pitch = cesiumViewer.camera.pitch
      vProps.roll = cesiumViewer.camera.roll
      vProps.heading = cesiumViewer.camera.heading
      const lat = cesiumViewer.camera.positionCartographic.latitude
      const lng = cesiumViewer.camera.positionCartographic.longitude
      if (lat && lng) {
        vProps.location = { lat, lng }
      }
    } catch (error) {
      // console.log(`Error updating cockpit cam orientation/location: ${error}`)
    }
  }
  return vProps
}

export const setCesiumViewerLocalDatetime = ({
  cesiumViewer,
  cesiumLib,
  iso8601Datetime
}) => {
  if (cesiumViewer && cesiumLib) {
    try {
      cesiumViewer.clockViewModel.currentTime = cesiumLib.JulianDate.fromIso8601(iso8601Datetime)
    } catch (error) {
      console.log(`Error updating cockpit cam currentTime: ${error}`)
    }
  }
}